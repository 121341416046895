import React from "react";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import RateReviewIcon from "@mui/icons-material/RateReview";
import WindPowerIcon from '@mui/icons-material/WindPower';
import DomainAddIcon from '@mui/icons-material/DomainAdd'

export const sectionsCategories: Array<string> = ["viewsSlider"];
export type SectionDetails = {
  name: string;
  mainTitle: string;
  subTitle: string;
  mainContent: string;
  subContent: string;
  details: string;
  img: string;
  icon?: React.ReactNode;
  btnLabel: string;
  path: string;
  hash: string;
};
// : Record<string, Array<SectionDetails>>
export const sectionsData: Record<string, Array<SectionDetails>> = {
  viewsSlider: [
    {
      img: "/images/hslider1.jpg",
      name: "",
      mainTitle: "MASSARI LTD.",
      subTitle: " Massari Ltd. is rapidly evolving to become a leading provider of EPC services in Syria and in the Middle East.",
      btnLabel: "Get started",
      mainContent: "",
      subContent: "",
      details: "",
      path: "/",
      hash: "views-slider",
    },
    {
      img: "/images/hslider2.jpg",
      name: "",
      mainTitle: "MASSARI LTD.",
      subTitle: "A Syrian based Limited Liability Company officially incorporated and registered in the Syrian economy ministry and has a C.T number 21. ",
      btnLabel: "Get started",
      mainContent: "",
      subContent: "",
      details: "",
      path: "/",
      hash: "views-slider",
    },
    {
      img: "/images/hslider3.jpeg",
      name: "",
      mainTitle: "MASSARI LTD.",
      subTitle: "We pride ourselves to be the first mover in Syria in the field of Renewable Energy.",
      btnLabel: "Get started",
      mainContent: "",
      subContent: "",
      details: "",
      path: "/",
      hash: "views-slider",
    },
  ],
  topServices: [
    {
      img: "",
      name: "",
      mainTitle: "A pioneer of innovative and skillful contractor",
      subTitle: "",
      btnLabel: "Read more",
      icon: < WorkspacePremiumIcon/>,
      mainContent: "",
      subContent: "",
      details: "",
      path: "/our-services",
      hash: "top-services",
    },
    {
      img: "",
      name: "",
      mainTitle: "Renewable Energy",
      subTitle: "",
      btnLabel: "Read more",
      icon: < WindPowerIcon/>,
      mainContent: "",
      subContent: "",
      details: "",
      path: "/our-services",
      hash: "top-services",
    },
    {
      img: "",
      name: "",
      mainTitle: "Developing Master-planned communities that meet the Syrian government",
      subTitle: "",
      btnLabel: "Read more",
      icon: < RateReviewIcon/>,
      mainContent: "",
      subContent: "",
      details: "",
      path: "/our-services",
      hash: "top-services",
    },
    {
      img: "",
      name: "",
      mainTitle: "Developing new construction competencies",
      subTitle: "",
      btnLabel: "Read more",
      icon: <DomainAddIcon />,
      mainContent: "",
      subContent: "",
      details: "",
      path: "/our-services",
      hash: "top-services",
    },
  ],
  inspectWorkingSafely: [
    {
      img: "",
      name: "home.content.section_2.name",
      mainTitle:"home.content.section_2.main_title",
      subTitle: "home.content.section_2.sub_title",
      btnLabel: "",
      mainContent: "home.content.section_2.main_content",
      subContent:"" ,
      details: "",
      path: "/",
      hash: "inspect-working-safely",
    },
  ],
  whatWeOffer: [
    {
      img: "/images/we-offer/what-we-offer1.jpg",
      name: "",
      mainTitle: "home.content.section_3.items.0.main_title",
      subTitle: "",
      btnLabel: "Read more",
      mainContent: "home.content.section_3.items.0.main_content",
      subContent: "",
      details: "",
      path: "/our-services/industrial-production",
      hash: "what-we-offer",
    },
    {
      img: "/images/we-offer/what-we-offer2.jpg",
      name: "",
      mainTitle: "home.content.section_3.items.1.main_title",
      subTitle: "",
      btnLabel: "Read more",
      mainContent: "home.content.section_3.items.1.main_content",
      subContent: "",
      details: "",
      path: "/our-services/automotive-production",
      hash: "what-we-offer",
    },
    {
      img: "/images/we-offer/what-we-offer3.jpg",
      name: "",
      mainTitle: "home.content.section_3.items.2.main_title",
      subTitle: "",
      btnLabel: "Read more",
      mainContent: "home.content.section_3.items.2.main_content",
      subContent: "",
      details: "",
      path: "/our-services/high-tech-and-electronics",
      hash: "what-we-offer",
    },
  ],
  whatWeOffer2: [
    {
      img: "/images/wwo2-1.jpg",
      name: "",
      mainTitle: "our_services.content.section_0.items.0.main_title",
      subTitle: "our_services.content.section_0.items.0.sub_title",
      btnLabel: "",
      mainContent: "our_services.content.section_0.items.0.main_content",
      subContent: "our_services.content.section_0.items.0.sub_title",
      details: "our_services.content.section_0.items.0.details",
      path: "",
      hash: "",
    },
    {
      img: "/images/wwo2-2.jpg",
      name: "",
      mainTitle: "our_services.content.section_0.items.1.main_title",
      subTitle: "our_services.content.section_0.items.1.sub_title",
      btnLabel: "",
      mainContent: "our_services.content.section_0.items.1.main_content",
      subContent: "our_services.content.section_0.items.1.sub_title",
      details: "our_services.content.section_0.items.1.details",
      path: "",
      hash: "",
    },
    {
      img: "/images/wwo2-3.jpg",
      name: "",
      mainTitle: "our_services.content.section_0.items.2.main_title",
      subTitle: "our_services.content.section_0.items.2.sub_title",
      btnLabel: "",
      mainContent: "our_services.content.section_0.items.2.main_content",
      subContent: "our_services.content.section_0.items.2.sub_title",
      details: "our_services.content.section_0.items.2.details",
      path: "",
      hash: "",
    },
    {
      img: "/images/wwo2-4.jpg",
      name: "",
      mainTitle: "our_services.content.section_0.items.3.main_title",
      subTitle: "our_services.content.section_0.items.3.sub_title",
      btnLabel: "",
      mainContent: "our_services.content.section_0.items.3.main_content",
      subContent: "our_services.content.section_0.items.3.sub_title",
      details: "our_services.content.section_0.items.3.details",
      path: "",
      hash: "",
    },
    {
      img: "/images/wwo2-5.jpg",
      name: "",
      mainTitle: "our_services.content.section_0.items.4.main_title",
      subTitle: "our_services.content.section_0.items.4.sub_title",
      btnLabel: "",
      mainContent: "our_services.content.section_0.items.4.main_content",
      subContent: "our_services.content.section_0.items.4.sub_title",
      details: "our_services.content.section_0.items.4.details",
      path: "",
      hash: "",
    },
    {
      img: "/images/wwo2-6.jpg",
      name: "",
      mainTitle: "our_services.content.section_0.items.5.main_title",
      subTitle: "our_services.content.section_0.items.5.sub_title",
      btnLabel: "",
      mainContent: "our_services.content.section_0.items.5.main_content",
      subContent: "our_services.content.section_0.items.5.sub_title",
      details: "our_services.content.section_0.items.5.details",
      path: "",
      hash: "",
    },
  ],
  clients: [
    {
      img: "",
      name: "Industrial",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "Commercial",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "Residential",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "Infrastructure",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "EPC contracts",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    
  ],
  clients2: [
    {
      img: "",
      name: "Design",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "Engineering",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "Topographic studies",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "Renewable Energy",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
    {
      img: "",
      name: "import/export",
      mainTitle: "",
      subTitle: "",
      btnLabel: "",
      mainContent: "",
      subContent: "",
      details: "",
      path: "",
      hash: "",
    },
  ],
};
