import React from "react";
import { LinkInfo } from "types";
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import SupportIcon from '@mui/icons-material/Support';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FileCopyIcon from '@mui/icons-material/FileCopy';
export const linksDetails: Array<LinkInfo> = [
  { label: "Home", path: "/", protected: false, icon: <HouseSidingIcon /> },
  { label: "Our services", path: "/our-services", protected: false, icon: <HomeRepairServiceIcon /> },
  { label: "About us", path: "/about-us", protected: false, icon: <SupportIcon /> },
  { label: "Portfolio", path: "/portfolio", protected: false, icon: <FileCopyIcon /> },
];
