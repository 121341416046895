import React, { Suspense, useMemo } from "react";
import { Box, AppBar, Toolbar, Container } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import { LinkInfo } from "types";
import {
  CustomDrawer,
  ThemeSwitch,
  MenuBar,
  Footer
} from "./components";
import { MenuBarSkeleton } from "shared/loadingSkeleton";
import { Logo } from "assets/svgs/Logo";
import { useAppLayoutHelpper } from "./components/hooks";
import { ReactQueryErrorProvider } from "shared/appContainers/components/reactQuery";
import { useWindowScrollerYPosition } from "./components/hooks/useWindowScroll";
import { useGetUIThemeMode } from "shared/hooks";
import { linksDetails } from "shared/constants";
import { uniqueId } from "lodash";


const AppLayout = () => {
  const yScroller = useWindowScrollerYPosition();

  const { isMediumScreen } = useAppLayoutHelpper();

  const themeMode = useGetUIThemeMode();


  const links = useMemo(() => mapLinks(linksDetails), []);
  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "100vh",
          justifyContent: "space-between",
          overflow:"hidden"
        }}
      >
        <Box
          className="navigator-main-container"
          sx={{
            flexGrow: 0,
            color: "custom.sub2",
            height: "80px",
            position: "absolute",
            top: "0px",
            width: "100%",
          }}
        >
          <AppBar
            className={`navigator-container ${
              !isMediumScreen && yScroller > 100 && yScroller < 130
                ? "navigator-container-hide"
                : "navigator-container-float"
            } 
            `}
            sx={{
              position:
                !isMediumScreen &&
                yScroller > 130 &&
                yScroller < 900 
                  ? "fixed"
                  : "relative",
              paddingRight: "0px !important",
              paddingLeft: "0px !important",
              // overflow: "hidden",
              transition: "all 0.2s ease-in",
              backgroundColor:
                !isMediumScreen && yScroller > 130 && yScroller < 900
                  ? themeMode === "dark"
                    ? "#000"
                    : "#fff"
                  : "transparent",
              overflow: "visible",
            }}
          >
            <Toolbar>
              {isMediumScreen ? (
                <Box className="logo-container">
                  <Link to="/">
                    <Logo />
                  </Link>
                </Box>
              ) : (
                <>
                  <Box className="logo-container">
                    <Link to="/">
                      <Logo />
                    </Link>
                  </Box>
                  <Suspense fallback={<MenuBarSkeleton />}>
                    <MenuBar
                      links={links}
                    />
                  </Suspense>
                </>
              )}
              <Suspense fallback={null}>
                <CustomDrawer
                  links={links}
                  isMediumScreen={isMediumScreen}
                />
              </Suspense>
              <Suspense fallback={null}>
                <ThemeSwitch />
              </Suspense>
            </Toolbar>
          </AppBar>
        </Box>
        <Box style={{ flexGrow: 1, display: "flex" }}>
          <Container
            disableGutters
            maxWidth={false}
            sx={{
              p: 0,
            }}
          >
            <ReactQueryErrorProvider>
              <Outlet />
            </ReactQueryErrorProvider>
          </Container>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        </Box>
      </Box>
    </>
  );
};


export default AppLayout;


export const mapLinks = (
  links: Array<LinkInfo>
): Array<LinkInfo & { id: string }> => {
  const mappedLinks = links.map((link) => ({ ...link, id: uniqueId() }));
  return mappedLinks;
};
