import React from "react";
import { Grid, Typography, Link as MUILink, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Logo } from "assets/svgs/Logo";

export const Footer = () => {
  const date = new Date();

  const copyrights = `MASSARI LTD C.O © ${date.getFullYear()}. All Rights Reserved`;
  return (
    <Box
      sx={{
        backgroundColor: "#272727",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        zIndex: "1002",
        height: "150px",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <Grid container alignItems="center" style={{ direction: "ltr" }}>
        <Grid item xs={12} md={6} textAlign="center">
          <MUILink component={Link} to={"/"} underline="none">
            <Logo />
          </MUILink>
        </Grid>
        <Grid item xs={12} md={6} textAlign="center">
          <Typography
            color="custom.main"
            fontSize={"1.2rem"}
            fontWeight={"bold"}
            sx={{ textTransform: "uppercase" }}
          >
            {"A pioneer of innovative and skillful contractor"}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap:"10px"
        }}
      >
        <Typography
          color="custom.main"
          sx={{ direction: "ltr", fontSize: "1rem" }}
        >
          {copyrights}
        </Typography>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <Typography
            color="custom.main"
            sx={{ direction: "ltr", fontSize: "0.6rem" }}
          >
            {`Powred by:`}&nbsp;&nbsp;
          </Typography>
          <a href="mailto:developerpro1000@gmail.com" style={{textDecoration:"none"}}>
            <Typography
              color="custom.hover"
              sx={{ direction: "ltr", fontSize: "0.6rem",  }}
            >
              Phoenix software &nbsp; Al-ankaa
            </Typography>
          </a>
        </Box>
      </Box>
    </Box>
  );
};
